export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        id: 'desktop-index',
        name: 'Escritorio',
        to: '/home',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavTitle',
        id: 'mainmnu-index',
        _children: ['Menu Principal']
      },
      {
        _name: 'CSidebarNavDropdown',
        id:"permission-index",
        name: 'Accesos',
        route: '/base',
        icon: 'cil-people',
        items: [
          {
            style: "display:none",
            id:"permission-index",
            name: 'Permisos',
            to: '/accesos/permisos'
          },
          {
            style: "display:none",
            id:"role-index",
            name: 'Tipo Usuario',
            to: '/accesos/tipos'
          },
          {
            style: "display:none",
            id: "user-index",
            name: 'Usuarios',
            to: '/accesos/usuarios'
          },  
          {
            style: "display:none",
            id: "permission-index",
            name: 'Permisos Asignados',
            to: '/accesos/permisos_asignados'
          }
        ]
      },
      /** mantenimientos */
      // {
      //   _name: 'CSidebarNavDropdown',
      //   id:"client-index",
      //   name: 'Mantenimientos',
      //   route: '/maintenance/client',
      //   icon: 'cil-cog',
      //   items: [
      //     {
      //       style: "display:none",
      //       id:"client-index",
      //       name: 'Clientes',
      //       to: '/maintenance/client'
      //     },
      //     {
      //       style: "display:none",
      //       id:"parkinglots-index",
      //       name: 'Estacinamientos',
      //       to: '/maintenance/parkinglots'
      //     },
      //   ]
      // },
    ]
  }
]